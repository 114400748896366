.navbar {
    background: rgb(100, 130, 100);
    width: 100%;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    
}
.navbar ul {
    display: flex;
    align-items: center;
}
.navbar li{
    display: inline-flex;
    text-decoration: none;
    margin-right: 1%;
}
.navbar .logo {
    margin-right: auto;
    padding-left: 1%; 
}
.navbar .logo img {
    width: 20%;  
}
.navbar .logo :hover{
    cursor: pointer;
}
.navbar .nav-items{
    width: 10%;
    justify-content: right;
    align-items: center;
    padding-right: 1%;
    transition: .2s;
    border-right: solid;
    border-right-width: 1%;
    font-size: 2vw;
}
.navbar .nav-items:hover{
    cursor: pointer;
    font-weight: bold;
    transform: scale(1.1);
}




/* .navbar .menu-icon{
    justify-content: right;
    border-radius: 10%;
    margin-left: 50%;
    background-color: rgb(150, 150, 150);
    width: 150px;
    opacity: 87%;
}
.navbar .menu-icon img {
    width: 100%;
}
.navbar .menu-icon :hover {
    cursor: pointer;
    border: 2px solid black;
}
.navbar .menu-dropdown{
justify-content: right;
margin-left: 50%;
} */
