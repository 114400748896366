body{
  font-size: 2vw;
  background-color: #DDCFAA;
  
}
.App {  
  background-image: 
  url("https://firebasestorage.googleapis.com/v0/b/laptop-lifestyle-ec9c9.appspot.com/o/assets%2FbackgroundPhoto.png?alt=media&token=7dcfca70-0de5-49c9-9756-cc6bcf849f7a"
     );
  background-position: center;

  background-attachment: fixed;
  background-repeat: no-repeat;
  margin: 0;
  z-index: 0;
}
.App .container{
  flex-grow: 1;
  position: relative;

  justify-content: center;
  align-items: center;
  text-align: center;
}
.btn{
  background: rgb(255, 255, 255, .8);
  width: 90%;
  font-weight: bold;
  font-size: 1em;
  border-radius: 4px;
}