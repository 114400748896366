.services{
    display: relative;
    margin: 8% 2%;
    justify-items: center;
    align-items: center;
}
.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;;
    grid-gap:  7%;
    
    
}
.grid-items{
    background-color: rgb(230,230,230, 0.9);
    overflow: hidden;
    border-radius: 30px;
    margin: auto 0;
    height: 100%;
    padding: 3% 7%;
}
.service-card{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: auto;
    opacity: 90%;
    transition: .2s; 
    }
.service-card-photo{
    justify-items: center;
    align-items: center;
}
.service-card-photo img{
    width: 85%;
    margin-top: 33%;
}
.service-card-info{
    display: flex;
    justify-items: center;
    align-items: center;
}
.service-card:hover{
    cursor: pointer;
    transform: scale(1.1);
    border-radius: 30px;
}
