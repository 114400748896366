.mission-statement{
    background-color: rgb(230,230,230, 0.8);
    margin: 3% 5%;
    border-radius: 10px;
    padding-bottom: 2%;
}
.core-values{
    display: inline-block;
    background-color: rgb(230,230,230, 0.8);
    margin: 1% 5%;
    border-radius: 10px;
    padding: 2% 0%;
}
.core-values-statement{
    
    padding-bottom: 2%;
    
}
.core-values li {
    text-align: left;
    float: left;
    margin: 1% 3%;
    border-radius: 10px;
}