.home-component{
    display: relative;
    line-height: 1.6;
    width: 60vw;
    margin: 0 auto;
}
.hero-statement .bam p::first-letter{
    font-weight: bold;
}
.hero-statement{
    background: rgb(255, 255, 255, .8);
    margin-bottom: 5%;
    border-radius: 20px;
    padding: 0% 1% 1% 1%;
}

.problem-statement{
    background: rgb(255, 255, 255, .8);
    border-radius: 15px;
    padding: 5px;
    margin-bottom: 5%;
}