.contact{
    background: rgb(255, 255, 255, .8);
    margin-top: 3%;
    margin-bottom: 5%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 3%;
    border-radius: 8px;
}
.contact-form{ 
    padding-left: 20%;
    padding-right: 20%;
    text-align: left;
    margin-top: 3%;
    
}
.contact-form input {
    width: 90%;
    min-height: 4vh;
    border-radius: 4px;
}
.contact-form textarea {
    width: 90%;
    height: 20vh;
}
